export * from "./layout";

// More info at https://collegeboard.atlassian.net/wiki/spaces/EA/pages/68244547/Levity
export enum LevityEvent {
    // On successful response of call to catapult ('Login' refers to authentication in the context of the catapult ecosystem, NOT the client's login flow. See the diagram for more details)
    Login =	"cb.core.iam.events.Login",

    // 6.5+	Valid for students and professional logins. Published after successful extension of login
    LoginExtended = "cb.core.iam.events.LoginExtended",
    
    // 5.7+	On failure of call to catapult / bsauth (if using custom login)
    LoginFailed = "cb.core.iam.events.LoginFailed",


    // Published after client has logged out or session has become invalid 
    Logout = "cb.core.iam.events.Logout",
    
    // After the retrieval and setting of temporary AWS credentials in browser
    AWSLogin = "cb.core.iam.events.AWSLogin",
    
    // After a refresh and setting of temporary AWS credentials
    AWSLoginRefresh = "cb.core.iam.events.AWSLoginRefresh",
    
    // Published before call to catapult lambda
    AWSLoginStarted = "cb.core.iam.events.AWSLoginStarted",
    
    // Published after successful call to catapult lambda
    AWSLoginFinished = "cb.core.iam.events.AWSLoginFinished",
    
    // Published after failure of call to catapult lambda
    AWSLoginFailed = "cb.core.iam.events.AWSLoginFailed",
    
    // Published if the client's clock differs greatly from the retrieved server time (retrieval done at call to catapult)
    InvalidLocalClock = "cb.core.iam.events.InvalidLocalClock",
    
    // legacy event for backward compatibility	
    LegacyLoginLogout = "widgets:custom:LoginLogout"	


}