import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { Helmet } from "react-helmet";

import App from "./App";
import { store } from "./redux/store";

const container = document.getElementById("attex");

if (container) {
    const { REACT_APP_SHOW_ADMIN_PAGES } = process.env;
    const roleType = 'emp'; //REACT_APP_SHOW_ADMIN_PAGES === "true" ? 'emp' : 'csr';
    // This comment is to make a dummy commit
    const root = createRoot(container);
    root.render(
        // <React.StrictMode>
        <>
            <Helmet>
                <script src="https://sdk.amazonaws.com/js/aws-sdk-2.7.20.min.js"></script>
                <script src={`${process.env.PUBLIC_URL}/levity-${roleType}-profile.js`} />
                <script src="https://atlas.collegeboard.org/widgets/release/2021-04-27/main.js" type="text/javascript"></script>

            </Helmet>
            <Provider store={store}>
                <React.Fragment>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <App />
                    </BrowserRouter>
                </React.Fragment>
            </Provider>
        </>
        // </React.StrictMode> 
    );
}

