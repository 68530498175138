import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

// Constants
import { LayoutType } from "../constants/layout";
import { LevityEvent } from "../constants";

// redux
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

// Auth State
import { useAuthStore } from "../zustand/authStore";

// All layouts containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import HorizontalLayout from "../layouts/Horizontal";

import { authProtectedFlattenRoutes, publicProtectedFlattenRoutes } from ".";
import { APICore } from "../helpers/api/apiCore";
interface IRoutesProps {}

const AllRoutes = (props: IRoutesProps) => {
  const { Layout } = useSelector((state: RootState) => ({
    Layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (Layout.layoutType) {
      case LayoutType.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  let GotLayout = getLayout();
  const api = new APICore();
  
  const logIn = useAuthStore((state) => state.logIn)
  const logOut = useAuthStore((state) => state.logOut)
  
  useEffect(() => {
    window.addEventListener(LevityEvent.AWSLogin, logIn);
    window.addEventListener(LevityEvent.Logout, logOut);
    window.addEventListener(LevityEvent.LoginFailed, logOut);
    return () => {
      window.removeEventListener(LevityEvent.AWSLogin, logIn);
      window.removeEventListener(LevityEvent.Logout, logOut);
      window.removeEventListener(LevityEvent.LoginFailed, logOut);
    };
  }, []);
  
  //   //  If we are not on the auth route, periodically check if the session is still valid
  //   //  Otherwise when creds expire the page will stop working
  //   let intervalTimer: NodeJS.Timeout;
  //   if (location.pathname !== '/auth/login') {
  //     intervalTimer = setInterval(() => {
  //       const { expireTimeInMS } = JSON.parse(sessionStorage.getItem("iamSession") || '{}');
  //       console.log('iamSession', sessionStorage.getItem("iamSession"), expireTimeInMS)
  //       const sessionInvalidOrExpired = !expireTimeInMS || Date.now() > expireTimeInMS;
  //       if (sessionInvalidOrExpired) {
  //         window.location.href = `/auth/login?next=${location.pathname}`;
  //       }
  //     }, 5000);
  //   }
  //   return () => {
  //     clearInterval(intervalTimer);
  //   }
  // }, [location.pathname]);

  // TODO: Is this necessary since it's in the Private Routes?
  // useEffect(() => {
  //   window.addEventListener(LevityEvent.AWSLogin, logIn);
  //   window.addEventListener(LevityEvent.Logout, logOut);
  //   window.addEventListener(LevityEvent.LoginFailed, logOut);
  //   return () => {
  //     window.removeEventListener(LevityEvent.AWSLogin, logIn);
  //     window.removeEventListener(LevityEvent.Logout, logOut);
  //     window.removeEventListener(LevityEvent.LoginFailed, logOut);
  //   };
  // }, []);


  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <DefaultLayout {...props} layout={Layout}>
                  {route.element}
                </DefaultLayout>
              }
              key={idx}
            />
          ))}
          ;
        </Route>

        <Route>
          {authProtectedFlattenRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                api.isUserAuthenticated() === false ? (
                  <Navigate
                    to={{
                      pathname: "/auth/login",
                      search: "next=" + route.path,
                    }}
                  />
                ) : (
                  <GotLayout {...props}>{route.element}</GotLayout>
                )
              }
              key={idx}
            />
          ))}
          ;
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default AllRoutes;
