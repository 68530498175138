import { create } from 'zustand'

interface AuthState {
  isAuthenticated: boolean
  logIn: () => void
  logOut: () => void
}

export const useAuthStore = create<AuthState>()((set) => ({
  isAuthenticated: false,
  logIn: () => set((_state) => ({ isAuthenticated: true })),
  logOut: () => set((_state) => ({ isAuthenticated: false })),
}))
