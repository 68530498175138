import React, { useEffect, useState } from "react";
import { Route, Navigate, RouteProps, useLocation } from "react-router-dom";

// helpers
import { APICore } from "../helpers/api/apiCore";

// Constants
import { LevityEvent } from "../constants";

// Auth State
import { useAuthStore } from "../zustand/authStore";
/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */

const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {
  const api = new APICore();
  const location = useLocation();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  
  return (
    <Route
      {...rest}
      render={(props: RouteProps) => {
        if (isAuthenticated === false) {
          // not logged in so redirect to login page with the return url
          return (
            <Navigate
              to={{
                pathname: `/auth/login?next=${location.pathname}`,
              }}
            />
          );
        }

        const loggedInUser = api.getLoggedInUser();

        // check if route is restricted by role
        if (roles && roles.indexOf(loggedInUser.role) === -1) {
          // role not authorised so redirect to login page
          return <Navigate to={{ pathname: "/" }} />;
        }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
